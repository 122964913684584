<template>
  <app-modal-list-payment-detail
      :show="showModalDetail"
      @close="showModalDetail = false"
      :payment_link="selectGrossCommission"
      :showBusinessDetails="isSupportRole"
      @update:payment_link="selectGrossCommission = $event"
    />
 
  <!-- Download CSV Modal -->
  <app-modal
    :show="show_modal_download_csv"
    @close="show_modal_download_csv = false"
  >
    <template v-slot:title>{{
      $t("payout.modal.download_csv_title")
    }}</template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("payout.modal.download_csv_description") }}
      </p>
    </template>
    <template v-slot:action>
      <app-button
        @click="downloadCsvGrossCommission"
        :showf70Icon="false"
        type="button"
        class="sm:ml-3 sm:w-auto"
      >
        {{ $t("payout.modal.download_csv_button") }}
      </app-button>
    </template>
  </app-modal>

  <!-- Download PDF Modal -->



  <app-layout class="bg-white">
    <div class="container mx-auto">
      <div class="mx-4 my-5 flex flex-row justify-between items-center">
        <span class="text-xl">
          {{
          $t("account.report.gross_commision_title")
        }}
        </span>
      </div>
      <hr class="mx-4 w-full border-b border-gray-200 mb-5" />
      <app-table
        :showEmptyButton="false"
        :apiResponse="apiPaginationResponse.data"
        :loading="apiPaginationLoading"
        @pagination-per-page-selected="per_page_changed"
        @params-changed="params_changed"
        @on-item-click="onItemClick"
        :filters="[
          {
              key: 'filter[payment_link_id]',
              type: 'text',
              placeholder: $t('account.transaction.payment_id'),
              value: '',
          },

          {
            key: 'filter[payment_time_at_between]',
            type: 'date',
            placeholder: $t('account.transaction.date_from_to'),
          },
          
          {
            key: 'filter[business_name]',
            type: 'text',
            placeholder: $t('account.report.business_name'),
          },
          {
            key: 'filter[period]',
            type: 'select',
            placeholder: $t('account.report.period'),
            value: {},
            items: [
              {
                id: 'daily',
                name: $t('general.daily'),
              },
              {
                id: 'week_to_date',
                name: $t('general.week_to_date'),
              },
              {
                id: 'month_to_date',
                name: $t('general.month_to_date') ,
              },
              {
                id: 'year_to_date',
                name: $t('general.year_to_date'),
              }
            ]
          },
          {
            key: 'filter[payment_method]',
            type: 'select',
            placeholder: $t('account.transaction.payment_type'),
            value: {},
            items: [
              {
                id: 'Online Banking B2C',
                name: 'Online Banking B2C',
              },
              {
                id: 'Online Banking B2B',
                name: 'Online Banking B2B',
              },
              {
                id: 'Credit / Debit Card',
                name:'Credit/Debit Card'
              },
              {
                id: 'Wallet',
                name:'eWallet'
              }
            ],
          },
       ]"
      >,
        <template v-slot:button>
          <app-button
            class="sm:ml-3 sm:w-auto"
            :disabled="loading"
            :showf70Icon="false"
            @click="downloadPdfGrossCommission"
          >
            {{ $t("payout.modal.download_pdf") }}
          </app-button>
          <app-button
          @click="downloadCsvGrossCommission"
            :showf70Icon="false"
            :disabled="loading"
            type="button"
            class="sm:ml-3 sm:w-auto"
          >
            {{ $t("payout.modal.download_csv_button") }}
          </app-button>
        </template>
        <template v-slot:header>
          <th class="font-bold">{{ $t("account.transaction.payment_id") }}</th>
          <th class="font-bold">{{ $t("account.report.business_name") }}</th>
          <th class="font-bold">{{ $t("account.report.sale_amount") }}</th>
          <th class="font-bold">{{ $t("account.report.charges") }}</th>
          <th class="font-bold">{{ $t("account.report.net_amount") }}</th>
          <th class="font-bold">{{ $t("account.report.payment_date") }}</th>

        </template>
        <template v-slot:body="data">
          <td>
            {{ data.model._id }}
          </td>
          
          <td>
            {{ data.model.business.name }}
          </td>
          <td>
            <p class="font-medium">
              {{
                $formats.currency(data.model.currency, data.model.amount ?? 0.0)
              }}
            </p>
          </td>
          <td>
            <p class="font-medium">
              {{
                    $formats.currency(
                      data.model.currency,
                      getPaymentAmount(data.model?.payments)?.charges
                    ) ?? "-"
                }}
            </p>
          </td>

          <td>
            <p class="font-medium">
              {{
                    $formats.currency(
                      data.model.currency,
                      getPaymentAmount(data.model?.payments)?.net_amount
                    ) ?? "-"
                }}
            </p>
          </td>
          <td>
            {{
                $moment(
                  Number(getPaymentAmount(data.model?.payments)?.payment_time?.$date?.$numberLong)
                ).format("DD MMM YYYY hh:mm a")
            }}
          </td>

        </template>

        <!--footer for floating amount: Total gross comission-->
        <template v-slot:footer>
          <tr>
            <td class="font-bold" colspan="1">
              {{ $t("wallet.transaction.total") }}
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            
            <td class="font-bold">
              {{
                $formats.currency(
                  apiPaginationResponse?.data?.summary?.currency,
                  apiPaginationResponse?.data?.summary?.total_amount ?? 0.0
                )
              }}
            </td>
            <td class="font-bold">
              {{
                $formats.currency(
                  apiPaginationResponse?.data?.summary?.currency,
                  apiPaginationResponse?.data?.summary?.total_payment_charges ?? 0
                )
              }}
            </td>
            <td class="font-bold">
              {{
                $formats.currency(
                  apiPaginationResponse?.data?.summary?.currency,
                  apiPaginationResponse?.data?.summary?.total_payment_net ?? 0
                )
              }}
            </td>
            <td>&nbsp;</td>
          </tr>
        </template>
      </app-table>
    </div>
  </app-layout>
</template>

<script>
import ROLE from "@/utils/const/role";

export default {
  data() {
    return {
      ROLE: ROLE,
      show_modal_download_csv: false,
      show_modal_upload_docs: false,
      filters: {},
      queryParams: "",
      showModalDetail: false,
      selectGrossCommission: null,
    };
  },
  created() {
    this.fetchListCommission();
  },

  computed: {
    apiPaginationResponse() {
      return this.$store.getters["paymentStore/apiPaginationResponse"];
    },
    apiPaginationLoading() {
      return this.$store.getters["paymentStore/apiPaginationLoading"];
    },
    apiPaginationPaymentLink() {
      return this.$store.getters["paymentStore/apiPaginationResponse"];
    }
  
  },

  methods: {
    params_changed(filters, queryParams) {
     this.queryParams = queryParams;
    this.$store.dispatch("paymentStore/getListCommission", queryParams);
     this.fetchListCommission();
    },
    per_page_changed(per_page) {
      this.filters.per_page = per_page;
      this.fetchListCommission();
    },

    onItemClick(data){
      this.showModalDetail = !this.showModalDetail;
      this.selectGrossCommission = data;
    },

    async fetchListCommission(){
      await this.$store.dispatch(
        "paymentStore/getListCommission",
        this.queryParams
      );
    },

    async downloadCsvGrossCommission(){
      this.show_modal_download_csv = false;
      this.submitLoading = true;
      const csv_url = await this.$store.dispatch(
        "paymentStore/downloadCommissionCSV",
        this.queryParams
      );
      this.submitLoading = false;
      window.location.href = csv_url.data;
      this.fetchListCommission();
    },

    async downloadPdfGrossCommission(){
      this.show_modal_download_csv = false;
      this.submitLoading = true;
      const pdf = await this.$store.dispatch(
        "paymentStore/downloadCommissionPdf",
        this.queryParams
      );
      this.submitLoading = false;
      window.open(pdf.data, "_blank");
      this.fetchListCommission();
    },
    getPaymentAmount(payments) {
      if (payments == null)
        return "-";

      var paymentAmount = {
        net_amount: null,
        amount: null,
        charges: null,
        payment_time: payments.payment_time,
      };

      payments.forEach((payment) => {
        paymentAmount.payment_time = payment.payment_time;
        paymentAmount.net_amount += payment.settlement_amount;
        paymentAmount.amount += payment.amount;
        paymentAmount.charges += (payment.amount - payment.settlement_amount)
      });

      return paymentAmount;
    },
  }
};
</script>
